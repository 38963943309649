<template>
  <div class="earnest-page">
    <van-config-provider>
      <van-nav-bar :title="pageTitle" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
    </van-config-provider>
    <div class="earnest-content">
      <div class="earnest-header">
        <div class="flex-wrap">
          <div>{{$t('field.securityDeposit')}} <span class="highlight">{{accountInfo.earnestStr}}</span></div>
          <div class="refund-btn" @click="handleRefund">{{$t('button.refund')}}</div>
        </div>
        <div class="extra">{{$t('field.minimumSecurityDeposit')}} <span class="highlight">{{minEarnest}}</span></div>
      </div>
      <div class="form-wrap">
        <div class="form-title">{{$t('common.add')}} {{$t('field.securityDeposit')}}</div>
        <div class="field-wrap">
          <van-field
            v-model="earnest"
            @input="inputAmount"
            class="form-field"
            type="number"
            size="large"
            :placeholder="$t('placeholder.default')"
          />
        </div>
      </div>
      <div class="page-button-wrap">
        <van-button
          size="large"
          type="primary"
          block
          @click="handleAddEarnest"
          :disabled="!earnest"
        >{{$t('button.submit')}}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import i18n from '@/assets/lang/index';
import { queryAccountInfo, addEarnest, refundEarnest, queryMinEarnest } from "@/api";

export default {
  setup() {
    const router = useRouter();
    const goBack = () => router.back();
    return {
      goBack
    };
  },
  
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      accountInfo: {},
      earnest: '',
      minEarnest: '',
      jsBrige: window.jsBridge,
    };
  },
  
  mounted() {
    this.fetchAccountInfo();
    this.fetchMinEarnest();
  },

  methods: {
    fetchAccountInfo() {
      queryAccountInfo().then(res => {
        this.accountInfo = res.data || {};
      });
    },
    fetchMinEarnest() {
      queryMinEarnest().then(res => {
        this.minEarnest = res.data || 0;
      });
    },
    handleAddEarnest() {
      this.$myDialog.confirm({
        title: i18n.global.t('alert.warnning'),
        message: i18n.global.t('alert.addSecurityDeposit'),
      })
      .then(() => {
        addEarnest({
          amount: this.earnest,
        }).then(res => {
          this.earnest = '';
          this.fetchAccountInfo();
        });
      })
      .catch(() => {});
    },
    handleRefund() {
      this.$myDialog.confirm({
        title: i18n.global.t('alert.warnning'),
        message: i18n.global.t('alert.refundSecurityDeposit'),
      })
      .then(() => {
        refundEarnest().then(res => {
          this.fetchAccountInfo();
        });
      })
      .catch(() => {});
      
    },
    inputAmount() {
      this.earnest = this.earnest.downFixed(4);
    },
  },
};
</script>

<style lang="less">
.earnest-page {
  padding-bottom: 80px;
  .earnest-content {
    padding: 16px;
    .earnest-header {
      padding: 12px;
      background: #fff;
      border-radius: 6px;
      margin-bottom: 12px;
      .flex-wrap {
        display: flex;
        justify-content: space-between;
        .highlight {
          font-weight: bold;
          color: var(--van-primary-color);
        }
        .refund-btn {
          color: var(--van-primary-color);
        }
      }
      .extra {
        padding-top: 8px;
        font-size: 10px;
        color: #6F6F93;
        line-height: 12px;
        font-weight: 400;
        .highlight {
          color: var(--van-primary-color);
        }
      }
      
    }
    .form-wrap {
      padding: 0 12px 16px;
      background: #fff;
      border-radius: 6px;
      overflow: hidden;
      .form-title {
        margin: 16px 0;
        font-size: 14px;
        color: #28293D;
        font-weight: 400;
      }
      .field-wrap {
        position: relative;
        .suffix-wrap {
          position: absolute;
          top: 0;
          right: 12px;
          width: 110px;
          line-height: 46px;
          text-align: right;
          font-weight: 400;
          color: #6F6F93;
          span {
            font-size: 16px;
            vertical-align: middle;
          }
          .triangle {
            width: 8px;
            margin-left: 12px; 
            vertical-align: middle;
          }
        }
      }
      .form-field {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        background: #F9FAFB;
        border-radius: 4px;
        &.with-suffix {
          padding-right: 110px;
        }
      }
      .field-extra {
        padding: 8px 0;
        font-size: 10px;
        color: #6F6F93;
        line-height: 12px;
        font-weight: 400;
        .highlight {
          color: var(--van-primary-color);
        }
      }
    }
    
    .page-button-wrap {
      // position: fixed;
      // left: 0;
      // bottom: 0;
      // width: 100%;
      padding: 16px 0;
      // background: #fff;
      box-sizing: border-box;
    }
    .tip-text {
      padding: 16px;
      font-size: 12px;
      color: #646566;
    }
  }
}
</style>
